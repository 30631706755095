<template>

  <a-card :bordered="false">
    <template slot="title">
      <a-row justify="space-between" type="flex">
        <a-col :span="6">
          <a-space>
            <span style="font-weight: bolder; font-size: 20px;">今日采集情况</span>
            <a-date-picker v-model="query.time" @change="handleQueryTimeChange" format="YYYY-MM-DD"/>
          </a-space>
        </a-col>
        <a-col>
          <a-radio-group v-model="currentTime" button-style="solid" default-value="a">
            <a-radio-button value="morning">
              上午
            </a-radio-button>
            <a-radio-button value="afternoon">
              下午
            </a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
    </template>
    <a-spin :spinning="loading">
        <a-row :gutter="12">
            <a-col :span="12">
                <a-statistic  style="margin-right: 50px" suffix="KG" title="厨余垃圾" :value="kitchenData.kitchenWaste"/>
                <span v-if="currentTime === 'afternoon'">
                <a-space v-if="false">
                    <span :style="{color: statistic[0][currentTime].rate>=0 ? 'green' : 'red'}">
                    <a-icon
                        :type="statistic[0][currentTime].rate>=0 ? 'caret-up' : 'caret-down'"/>
                    <span>{{ statistic[0][currentTime].rate }}%</span>
                    </span>
                    <span style="color: grey; font-size: small;">同比上午</span>
                </a-space>
                </span>
                <div v-else style="height: 21px;">
                </div>
                <div id="todayCollectKitchenChart" style="height: 200px;"></div>
            </a-col>
            <a-col :span="12">
                <a-statistic  style="margin-right: 50px" suffix="KG" title="其他垃圾"/>
                <span v-if="currentTime === 'afternoon'">
                <a-space v-if="false">
                    <span :style="{color: statistic[0][currentTime].rate>=0 ? 'green' : 'red'}">
                    <a-icon
                        :type="statistic[0][currentTime].rate>=0 ? 'caret-up' : 'caret-down'"/>
                    <span>{{ statistic[0][currentTime].rate }}%</span>
                    </span>
                    <span style="color: grey; font-size: small;">同比上午</span>
                </a-space>
                </span>
                <div v-else style="height: 21px;">
                </div>
                <div id="todayCollectOtherChart" style="height: 200px;"></div>
            </a-col>
        </a-row>
        </a-spin>
  </a-card>
</template>

<script>
import {queryCenterTodayAnalyzeApi} from '@/api/center'
import { Area } from '@antv/g2plot';
import moment from 'moment'
export default {
    data() {
        return{
            loading: true,
            statistic: [
                {
                'morning': {weight: 0},
                'afternoon': {weight: 0, rate: 0}
                },
                {
                'morning': {weight: 0},
                'afternoon': {weight: 0, rate: 0}
                }
            ],
            currentTime: 'morning',
            query: {
                time: moment(),
                timeStatus: 'morning'
            },
            kitchenChart: null,
            otherChart: null,
            kitchenData: {},
            otherData: {},
        }
    },
    watch: {
        currentTime() {
            this.query.timeStatus = this.currentTime
            this.queryCenterTodayAnalyze()
        }
    },
    mounted() {
        this.queryCenterTodayAnalyze()
    },
    methods: {
        handleQueryTimeChange(e) {
            this.query.time = e
            this.query.timeStatus = this.currentTime
            this.queryCenterTodayAnalyze()
        },
        drawKitchenChart() {
            if(this.kitchenChart) {
                this.kitchenChart.update({
                    data: this.kitchenData.kitchenList
                })
                this.loading = false
                return
            }
            this.kitchenChart = new Area('todayCollectKitchenChart', {
                data: this.kitchenData.kitchenList,
                xField: 'time',
                yField: 'kitchenWaste',
                color: '#808080',
                smooth: true,
                xAxis: {
                    range: [0, 1],
                    label: {
                        formatter: (text) => {
                        return text + ":00"
                        }
                    },
                },
            })
            this.kitchenChart.render();
            this.loading = false

        },
        drawOtherChart() {
            if(this.otherChart) {
                this.otherChart.update({
                    data: this.otherData.otherList
                })
                this.loading = false
                return
            }
            this.otherChart = new Area('todayCollectOtherChart', {
                data: this.otherData.otherList,
                xField: 'time',
                yField: 'other',
                smooth: true,
                color: '#3E92F1',
                xAxis: {
                    range: [0, 1],
                    label: {
                        formatter: (text) => {
                        return text + ":00"
                        }
                    },
                },
            })
            this.otherChart.render();
            this.loading = false

        },
        queryCenterTodayAnalyze() {
            queryCenterTodayAnalyzeApi({...this.query}, this.$route.query.moduleCode).then(res => {
                let data = res.result
                this.kitchenData = {
                    kitchenWaste: data.kitchenWaste,
                    kitchenList: data.detailedData
                }
                this.otherData = {
                    otherWaste: data.otherWaste,
                    otherList: data.detailedData
                }
                this.drawKitchenChart()
                this.drawOtherChart()
                this.loading = false
            })

        }
    }
}
</script>
<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>